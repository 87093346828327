import React, { useState } from "react";
import axios from "axios";
import RecordCount from "./ContentCount";
import SearchResults from "./SearchResults";

function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const results = await axios.get(
      `${process.env.REACT_APP_API_HOST}/contents/search?content=${searchTerm}`,
    );
    setSearchResults(results.data);
  };

  return (
    <section
      className="section"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="container" style={{ maxWidth: "500px" }}>
        <RecordCount />
        <br />
        <form
          onSubmit={handleSubmit}
          className="field has-addons"
          style={{ width: "100%" }}
        >
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <div className="control">
            <button className="button is-info">Search</button>
          </div>
        </form>
        <br />
        <SearchResults contents={searchResults} text={"Search Results"} />
      </div>
    </section>
  );
}

export default Search;
