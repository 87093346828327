import React, { useState } from "react";
import { useParams } from "react-router-dom";

const Unsubscribe = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const { uuid } = useParams();

  const handleConfirm = async () => {
    setLoading(true);
    try {
      // Make API call to confirm subscription
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/newsletters/unsubscribe/${uuid}`,
      );
      const data = await response.json();
      if (data.message) {
        setSuccess(true);
        setMessage(data.message);
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="section columns is-centered">
      <div className="column is-half">
        <div className="card">
          <div className="card-content">
            {error && (
              <div className="notification is-danger">
                Failed to unsubscribe: {error}
              </div>
            )}
            {success ? (
              <div className="notification is-success">{message}</div>
            ) : (
              <>
                <p>Sorry to see you go. Are you sure?</p>
                <br />
                <button
                  className="button is-warning"
                  onClick={handleConfirm}
                  disabled={loading}
                >
                  {loading ? (
                    <progress
                      className="progress is-small is-primary"
                      max="100"
                    >
                      15%
                    </progress>
                  ) : (
                    "Unsubscribe"
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Unsubscribe;
