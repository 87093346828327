import React, { useEffect, useState } from "react";
import LoginWithGoogle from "./LoginWithGoogle";
import { Link } from "react-router-dom";
const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [showForm, setShowForm] = useState(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(formData),
        },
      );

      const data = await response.json();
      if (response.status === 200) {
        setSuccess(true);
        setError("");
        setMessage("Account created successfully. Please log in.");
        setShowForm(false);
      } else if (data.code === 11000) {
        setError("The user already exists in the record.");
        setSuccess(false);
      } else {
        setError("Something went wrong while processing.");
        setSuccess(false);
      }
    } catch (error) {
      setMessage(error.message);
      console.error(error);
    }
  };

  return (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "40%" : "80%" }}
      >
        <h1 className="title is-bold">Sign Up</h1>
        <LoginWithGoogle />
        <br />
        <hr />
        <p style={{ fontWeight: "bold" }}>
          Prefer to Sign up with email and password?
        </p>
        <br />
        {showForm && (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="password"
                  placeholder="Enter a password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button className="button is-primary is-fullwidth">
                  Sign Up
                </button>
              </div>
            </div>
          </form>
        )}
        <br />
        Existing User? <Link to="/users/login">Login</Link>
        <br />
        <br />
        {error && (
          <div className="message is-danger">
            <div className="message-body">{error}</div>
          </div>
        )}
        {success && (
          <div className="message is-success">
            <div className="message-body">{message}</div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SignUp;
