import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaBookmark, FaEdit, FaTrash } from "react-icons/fa";
import { FaSquareCheck } from "react-icons/fa6";
import { connect, useSelector } from "react-redux";

function Post({ post, content, images }) {
  const [isBookmarked, setBookmarked] = useState(false);

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state?.user?.currentUser);
  // const admin = useSelector(state => state?.user?.currentUser?.role === 'admin');

  let { slug } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [slug]);

  isLoggedIn &&
    fetch(
      `${process.env.REACT_APP_API_HOST}/bookmarks/check/${user["_id"]}/${post["_id"]}`,
    )
      .then((response) => response.json())
      .then((data) => {
        // Handle success or error response
        // Update bookmark status in the component state if needed
        setBookmarked(data.bookmarked);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_HOST}/contents/${post["_id"]}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Article deleted successfully");
          window.location.replace("/");
          // Optionally, update state or perform any other actions
        } else {
          throw new Error("Failed to delete article");
        }
      })
      .catch((error) => {
        console.error("Error deleting article:", error);
        // Optionally, display an error message to the user or update component state
      });
  };

  const confirmDelete = () => {
    const confirmResult = window.confirm(
      "Are you sure you want to delete this article?",
    );
    if (confirmResult) {
      handleDelete(post.id);
    }
  };

  const handleBookmarkClick = () => {
    if (isBookmarked) {
      // Already bookmarked, handle removal logic here
      // Send DELETE request to remove the saved article
      // Example using fetch API:
      fetch(
        `${process.env.REACT_APP_API_HOST}/bookmarks/${user["_id"]}/${post["_id"]}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          // Handle success or error response
          // Update bookmark status in the component state if needed
          setBookmarked(false);
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    } else {
      // Not bookmarked, handle save logic here
      // Send POST request to save the article
      // Example using fetch API:
      fetch(`${process.env.REACT_APP_API_HOST}/bookmarks`, {
        method: "POST",
        body: JSON.stringify({ articleId: post["_id"], userId: user["_id"] }), // Pass any required data
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle success or error response
          // Update bookmark status in the component state if needed
          setBookmarked(true);
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    }
  };

  return (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        {isLoggedIn && (
          <span className="is-flex is-justify-content-space-between">
            {!post.isDraft && !isBookmarked && (
              <a onClick={handleBookmarkClick} className="card-footer-item">
                <span className="icon is-small" title="Bookmark">
                  <FaBookmark />
                </span>
              </a>
            )}

            {!post.isDraft && isBookmarked && (
              <a onClick={handleBookmarkClick} className="card-footer-item">
                <span className="icon is-small" title="Remove Bookmark">
                  <FaSquareCheck />
                </span>
              </a>
            )}
            {(user._id === post.userId || user.role === "admin") && (
              <a
                href={`/article/${post.slug}/edit`}
                className="card-footer-item"
              >
                <span className="icon is-small" title="Edit">
                  <FaEdit /> {/* Render the pencil icon */}
                </span>
              </a>
            )}

            {(user._id === post.userId || user.role === "admin") && (
              <a onClick={confirmDelete} className="card-footer-item">
                <span className="icon is-small" title="Delete">
                  <FaTrash /> {/* Render the pencil icon */}
                </span>
              </a>
            )}
          </span>
        )}
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <div
                  style={{ fontFamily: "monospace" }}
                  className="title is-size-3 mb-10"
                >
                  {post.title}
                  <p>{post.isDraft && "[Draft]"}</p>
                </div>
                <div
                  style={{ fontFamily: "monospace" }}
                  className="subtitle is-size-6 mb-2"
                >
                  {post.sub_title ? post.sub_title : ""}
                </div>
                <span className="is-flex is-align-items-center">
                  {post.category !== "book_summary" && (
                    <a href={`/profile/${post.author_uuid}`}>
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                        }}
                        src={post?.author_image ? post.author_image : ""}
                        alt="author"
                      />
                    </a>
                  )}
                  <a href={`/profile/${post.author_uuid}`}>
                    <p className="byline ml-3 has-text-weight-bold">
                      {post.author_name ? post.author_name : ""}
                    </p>
                  </a>
                </span>
                {post.story_image && <figure>
                  <img src={post.story_image} alt="representational" />
                  <figcaption>
                    {" "}
                    <small style={{ fontSize: "10px" }}>
                      Representational Image
                    </small>{" "}
                  </figcaption>
                </figure>}
              </div>
            </div>
            <div
              className="content is-size-6"
              style={{ fontFamily: "monospace" }}
              dangerouslySetInnerHTML={{ __html: `${content}` }}
            />
          </div>
        </div>
        <hr />
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(Post);
