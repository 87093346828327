import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Post from "./Post";

function Article() {
  let { slug } = useParams();
  const [article, setArticle] = useState({});
  const [content, setContent] = useState("");
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/contents/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        setArticle(data);
        setContent(data.content.replace(/^"|"$/g, "").replace(/<br><br>/, "").replace(/\\/g, ""));
      });
  }, [slug]);

  return (
    <>
      <Post post={article} content={content} />
    </>
  );
}
export default Article;
