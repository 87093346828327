import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { updateProfile } from "../redux/actions/userActions"; // Import action to update user profile
import { FaEdit } from "react-icons/fa"; // Import edit icon from react-icons/fa
import axios from "axios";
import { useParams } from "react-router-dom"; // Import Axios for HTTP requests

const ProfilePage = ({ userData, updateProfile }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [isFollowing, setIsFollowing] = useState(false); // State for follow button

  let { uuid } = useParams();

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.replace("/");
    }

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/users/profile/${uuid}`,
        );
        const userData = response.data;
        setName(userData.name);
        setEmail(userData.email);
        setProfileImage(userData.profile_image);

        // Fetch user subscriptions to check if already following
        const subscriptionsResponse = await axios.get(
          `${process.env.REACT_APP_API_HOST}/newsletters/subscriptions/${currentUser.email}`,
        );
        const subscriptions = subscriptionsResponse.data;
        // Check if the user is following the author associated with the profile
        const isFollowingAuthor = subscriptions.some(
          (subscription) => subscription.following_author === userData.email,
        );
        setIsFollowing(isFollowingAuthor);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [isLoggedIn]);

  const handleSaveProfile = () => {
    const updatedUser = { ...currentUser, name, email, profileImage };
    updateProfile(updatedUser);
    setIsEditing(false); // Exit edit mode after saving
  };

  const handleFollowAuthor = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/newsletters/subscribe`,
        {
          email: currentUser.email,
          following_author: email, // current user is going to follow this email id
        },
      );
      if (response.status === 200) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
        console.error("Failed to follow author");
      }
    } catch (error) {
      setIsFollowing(false);
      console.error("Failed to follow author", error);
    }
  };

  const handleUnfollowAuthor = async () => {
    try {
      // Assuming you have the author's email and the user's email stored in variables
      const authorEmail = email;
      const userEmail = currentUser.email;

      // Make a DELETE request to your API endpoint
      const response = await axios.delete(
        `${process.env.REACT_APP_API_HOST}/newsletters/unsubscribe/author`,
        {
          data: { authorEmail, userEmail },
        },
      );

      if (response.status === 200) {
        // Handle successful unfollow
        console.log("Unfollowed author successfully");
        setIsFollowing(false);
      } else {
        console.error("Failed to unfollow author");
      }
    } catch (error) {
      console.error("Error unfollowing author", error);
    }
  };

  return (
    <div className="container mt-4">
      <h1 className="title is-4 has-text-centered mt-5">Profile Details</h1>
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="box">
            <div className="columns is-vcentered mb-4">
              <div className="column is-one-quarter">
                <figure className="image is-128x128">
                  <img
                    src={profileImage}
                    alt="Profile"
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  />
                  <br />
                  {isEditing && (
                    <div>
                      <input
                        type="text"
                        className="input"
                        value={profileImage}
                        onChange={(e) => setProfileImage(e.target.value)}
                      />
                      {/*<button className="button is-small" onClick={handleSaveProfile}>Save</button>*/}
                    </div>
                  )}
                  {!isEditing && (
                    <FaEdit
                      className="edit-icon"
                      onClick={() => setIsEditing(true)}
                    />
                  )}
                </figure>
              </div>
              <div className="column">
                <div className="content mt-4">
                  {isEditing ? (
                    <>
                      <input
                        type="text"
                        className="input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <input
                        type="email"
                        className="input mt-2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <br />
                      <button
                        className="button is-small mt-2"
                        onClick={handleSaveProfile}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <>
                      <p>
                        <strong>Name:</strong> {name}
                      </p>
                      <p>
                        <strong>Email:</strong> {email}
                      </p>
                      {/* Add follow/unfollow button with conditional rendering */}
                      {currentUser.email !== email && (
                        <>
                          {isFollowing ? (
                            <button
                              className="button is-danger is-small mt-2"
                              onClick={handleUnfollowAuthor}
                            >
                              Unfollow Author
                            </button>
                          ) : (
                            <button
                              className="button is-success is-small mt-2"
                              onClick={handleFollowAuthor}
                            >
                              Follow Author
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = {
  updateProfile, // Include updateUserProfile action in mapDispatchToProps
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
