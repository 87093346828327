import axios from "axios";
const userService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/users/login`,
        { email, password },
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  fetchUserData: async () => {
    try {
      // Make a GET request to the API endpoint that returns user data
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/users/current_user`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage
          },
        },
      );

      // Check if the response contains user data
      if (response.data) {
        return response.data; // Return the user data
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      throw new Error("Error fetching user data");
    }
  },
  // Method to log out the user
  logout: async () => {
    try {
      // Make a POST request to the logout API endpoint
      await axios.post(`${process.env.REACT_APP_API_HOST}/users/logout`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage
        },
      });
    } catch (error) {
      throw new Error("Error logging out:");
    }
  },

  // Update profile
  updateProfile: async (userData) => {
    try {
      const token = localStorage.getItem("token"); // Assuming you have a token stored in localStorage
      if (!token) {
        throw new Error("No token found");
      }

      // Send PUT request to update profile
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/users/update-profile`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      return response.data; // Return updated user data
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error; // Throw error for handling in the component
    }
  },
  // Other user-related API calls like register, forgot password, etc.
};

export default userService;
