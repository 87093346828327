import React, { useEffect, useState } from "react";
import axios from "axios";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/users/forget-password`,
        {
          email,
        },
      );

      if (response.status === 200) {
        setMessage(
          "Password reset link will be sent to the email if the email exists in our record.",
        );
        setShowForm(false);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Failed to send password reset link.");
    }
  };

  return (
    <div className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "40%" : "80%" }}
      >
        <h1 className="title has-text-centered">Forget Password?</h1>
        <p>
          Enter the email address associated with your account and we’ll send
          you a link to reset your password.
        </p>
        <br />
        {error && showForm && (
          <p className="help is-danger">
            <strong>{error}</strong>
          </p>
        )}
        {message.length > 0 && (
          <div className="message is-success">
            <div className="message-body">{message}</div>
          </div>
        )}
        {showForm && (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  type="email"
                  className="input"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className="button is-primary is-fullwidth"
                >
                  Send Password Reset Link
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;
