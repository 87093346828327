import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.bubble.css";

const EditorCommon = ({ type }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state?.user?.currentUser);
  const hidden = true;
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [category, setCategory] = useState("tinybyt");
  const [newsletter, setNewsletter] = useState(false);
  const [error, setError] = useState(null);
  const [errorVisible, setErrorVisible] = useState(false);
  const [id, setId] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [articleImage, setArticleImage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const { quill, quillRef } = useQuill({
    theme: "bubble",
    placeholder: "Write your story...",
  });

  let { slug, publication } = useParams();

  const dismissError = () => {
    setError(null);
    setErrorVisible(false);
  };

  const handleSendNewsletter = (event) => {
    setNewsletter(true);
  };

  let submitUrl = "";
  if (type === "new") {
    submitUrl = `${process.env.REACT_APP_API_HOST}/contents`;
  } else {
    submitUrl = `${process.env.REACT_APP_API_HOST}/contents/${id}`;
  }

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.replace("/");
    }

    (async function loadContent() {
      if (type === "new") {
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/contents/${slug}`,
      );
      const data = await response.text();
      const result = JSON.parse(data);
      setId(result["_id"]);
      setTitle(result.title);
      setSubTitle(result.sub_title);
      setCategory(result.category);
      // setAuthorName(result.author_name);
      // setAuthorUuid(result.author_uuid);
      // setAuthorImage(result.author_image);
      setContent(result.content);
      setArticleImage(result.story_image);
      quill.root.innerHTML = result.content;
    })();

    if (quill && content) {
      // Check if Quill and content are available
      quill.root.innerHTML = content; // Set the content in Quill editor
    }

    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
    }

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [quill]);

  async function handleSave(e) {
    e.preventDefault();
    setIsSaving(true);

    try {
      const response = await axios.post(
        submitUrl,
        {
          title,
          content,
          category,
          author_name: user.name,
          author_email: user.email,
          publication,
          author_uuid: user.uuid,
          author_image: user.profile_image,
          articleImage,
          summary: "",
          sub_title: subTitle,
          isDraft: true,
          sendNewsletter: false,
          userId: user._id,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      );
      if (response.status === 201 || response.status === 200) {
        // Simulate a delay to show the loading animation
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
        window.location.pathname !== `/article/${response.data["slug"]}/edit` &&
          window.location.replace(`/article/${response.data["slug"]}/edit`);
        setIsSaving(false);
      }
    } catch (e) {
      console.log(e);
      setIsSaving(false);
      if (e.response && e.response.status === 400) {
        setError(
          "Error: Invalid data. Please check your inputs and try again.",
        );
        setErrorVisible(true);
        setTimeout(() => {
          setError(null);
          setErrorVisible(false);
        }, 5000); // Error disappears after 5 seconds
      } else {
        setError("Error: Something went wrong. Please try again later.");
        setErrorVisible(true);
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post(
        submitUrl,
        {
          title,
          content,
          category,
          author_name: user.name,
          author_email: user.email,
          publication,
          author_uuid: user.uuid,
          author_image: user.profile_image,
          articleImage,
          summary: "",
          sub_title: subTitle,
          isDraft: false,
          sendNewsletter: newsletter,
          userId: user._id,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      );
      if (response.status === 201 || response.status === 200) {
        window.location.replace(`/article/${response.data["slug"]}`);
      }
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 400) {
        setError(
          "Error: Invalid data. Please check your inputs and try again.",
        );
        setErrorVisible(true);
        setTimeout(() => {
          setError(null);
          setErrorVisible(false);
        }, 5000); // Error disappears after 5 seconds
      } else {
        setError("Error: Something went wrong. Please try again later.");
        setErrorVisible(true);
      }
    }
  }

  async function generateContent(e) {
    e.preventDefault();

    // disable button and show loader
    setIsButtonDisabled(true);
    setIsLoading(true);

    // Post this to OpenAI API
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/ai`, {
      method: "POST",
      body: JSON.stringify({ prompt: title, text: content }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    const data = await response.text();
    const result = JSON.parse(data);
    quill.setText(result.replace(/^"|"$/g, "").replace(/^<br><br>/, ""));
    setIsButtonDisabled(false);
    setIsLoading(false);
    //setOutput(result.replace(/^"|"$/g, '').replace(/^<br><br>/, ""));
  }

  return (
    <section>
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "70%" : "95%" }}
      >
        {errorVisible && (
          <div className="notification is-danger">
            {error}
            <button className="delete" onClick={dismissError}></button>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="buttons is-flex is-justify-content-flex-end mb-3">
            <button
              className={`button is-success mr-2 has-text-weight-bold is-small is-rounded ${isSaving}? 'loading' :''`}
              type="button"
              onClick={handleSave}
              disabled={isSaving}
            >
              Save
            </button>
            <button
              className="button is-success has-text-weight-bold is-small is-rounded"
              type="submit"
              onClick={handleSubmit}
            >
              Publish
            </button>
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input is-large is-static has-text-weight-bold ml-3"
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <input
                className="input is-static ml-3"
                type="text"
                placeholder="Add a subtitle"
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
              />
            </div>
          </div>
          {!hidden && (
            <div className="control">
              <button
                className="button is-primary"
                onClick={generateContent}
                disabled={isButtonDisabled}
              >
                {isLoading ? <span className="loader"></span> : "AI Assist"}
              </button>
            </div>
          )}

          <div className="field">
            <div className="control">
              <input
                className="input is-static ml-3"
                type="text"
                placeholder="Story Image Url"
                value={articleImage}
                onChange={(e) => {
                  setArticleImage(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="field">
            <div className="control" style={{ height: "none" }}>
              <div ref={quillRef} />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input type="checkbox" onChange={handleSendNewsletter} />
                <span className="ml-2">Send Newsletter</span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(EditorCommon);
