import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [showForm, setShowForm] = useState(true);
  const { token } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/users/reset-password`,
        {
          password,
          token,
        },
      );

      if (response.data.message === "Password reset successful.") {
        setMessage("Password reset was successful! Login to continue.");
        setShowForm(false);
        console.log("Password reset successfully");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Failed to reset password.");
      console.log("err", error);
    }
  };

  return (
    <div className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "40%" : "80%" }}
      >
        <h1 className="title has-text-centered">Reset Password</h1>
        {error && (
          <p className="help is-danger">
            <strong>{error}</strong>
          </p>
        )}
        {message.length > 0 && (
          <div className="message is-success">
            <div className="message-body">{message}</div>
          </div>
        )}
        {showForm && (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  type="password"
                  className="input"
                  value={password}
                  placeholder="Enter new password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Confirm Password</label>
              <div className="control">
                <input
                  type="password"
                  className="input"
                  value={confirmPassword}
                  placeholder="Confirm password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className="button is-primary is-fullwidth"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
