import React from "react";
import { connect } from "react-redux";
import EditorCommon from "./share/EditorCommon";

const AIEditor = () => {
  return <EditorCommon type="new" />;
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(AIEditor);
