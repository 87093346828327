import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk"; // Import redux-thunk
import { composeWithDevTools } from "@redux-devtools/extension";

// Import your root reducer
import rootReducer from "../reducers/rootReducer";

import { getUserDataFromLocalStorage } from "../../utils/localStorage"; // Import local storage utility

// Retrieve user data from local storage
const initialUserData = getUserDataFromLocalStorage();

// Create the Redux store with middleware applied
const store = createStore(
  rootReducer,
  {
    user: {
      currentUser: initialUserData,
      isLoggedIn: !!initialUserData,
    },
  },
  composeWithDevTools(applyMiddleware(thunk)), // Apply redux-thunk middleware
);

export default store;
