import React, { useEffect, useState } from "react";
import "bulma/css/bulma.css";
function About() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      className="section"
      style={{
        padding: "20px",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        <h1 className="title">About Us</h1>
        <p>
          The TinyByt is all about creating contents that makes you smarter
          everyday.
          <br />
          <br />
          Subscribe to our newsletter and receive updates straight to your
          email.
          <br />
          Thank you for using The TinyByt.
        </p>
      </div>
    </section>
  );
}
export default About;
