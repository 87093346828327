import React from "react";

function Footer() {
  return (
    <footer className="footer" style={{ backgroundColor: "white" }}>
      <div className="container has-text-centered">
        <p className="content">
          Copyright &copy; {new Date().getFullYear()} The TinyByt. All rights
          reserved.
        </p>
        <p>
          <a href="/about-us">About Us</a>
        </p>
        <p>
          <a href="/contact-us">Contact</a>
        </p>
        <p>
          <a href="/privacy-policy">Privacy Statement</a>
        </p>
        <p>
          <a href="/user-agreement">User Agreement</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
