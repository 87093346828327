import React, { useState } from "react";
import { useParams } from "react-router-dom";

const ConfirmSubscription = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const { uuid } = useParams();

  const handleConfirm = async () => {
    setLoading(true);
    try {
      // Make API call to confirm subscription
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/newsletters/confirm/${uuid}`,
      );
      const data = await response.json();
      if (data.message) {
        setSuccess(true);
        setMessage(data.message);
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="section columns is-centered">
      <div className="column is-half">
        <div className="card">
          <div className="card-content">
            {error && (
              <div className="notification is-danger">
                Failed to confirm subscription: {error}
              </div>
            )}
            {success ? (
              <div className="notification is-success">{message}</div>
            ) : (
              <button
                className={`button ${loading ? "is-loading" : "is-success"}`}
                onClick={handleConfirm}
                disabled={loading}
              >
                {loading ? (
                  <progress className="progress is-small is-primary" max="100">
                    15%
                  </progress>
                ) : (
                  "Confirm Subscription"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmSubscription;
