import React, { useEffect, useState } from "react";
import "bulma/css/bulma.css";
function Contact() {
  const [width, setWidth] = useState(window.innerWidth);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your submit logic here, such as sending form data to a server
    console.log(formData);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      className="section"
      style={{
        padding: "20px",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        <h1 className="title">Contact Us</h1>
        You can reach us at: &nbsp;
        <a href="mailto:hello@tinybyt.com">hello@tinybyt.com</a>
      </div>
    </section>
  );
}
export default Contact;
