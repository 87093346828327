import React, { useEffect, useState } from "react";

const SendNewsletters = () => {
  const [code, setCode] = useState("");
  const [category, setCategory] = useState("books");
  const [frequency, setFrequency] = useState("daily");
  const [env, setEnv] = useState("test");
  const [width, setWidth] = useState(window.innerWidth);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = fetch(
        `${process.env.REACT_APP_API_HOST}/newsletters/send`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ code, category, frequency, env }),
        },
      );
      let data = await response.json();
      if (data.status === 200) {
        setMessage("Emails are being sent to all the subscribers!");
        setShowForm(false);
      } else {
        setError("Something went wrong!");
      }
    } catch (e) {
      setError("Something went wrong!");
    }
    // .then((res) => res.json())
    // .then((data) => {
    //     console.log(data);
    // });
  };

  return (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "40%" : "100%" }}
      >
        {error && showForm && (
          <p className="help is-danger">
            <strong>{error}</strong>
          </p>
        )}
        {message.length > 0 && (
          <div className="message is-success">
            <div className="message-body">{message}</div>
          </div>
        )}
        {setShowForm && (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Code</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Category</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    value={category}
                    onChange={(event) => setCategory(event.target.value)}
                  >
                    <option value="books">Books</option>
                    <option value="fiction">Fiction</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Frequency</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    value={frequency}
                    onChange={(event) => setFrequency(event.target.value)}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Environment</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    value={env}
                    onChange={(event) => setEnv(event.target.value)}
                  >
                    <option value="test">Test</option>
                    <option value="prod">Prod</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button className="button is-link">Submit</button>
              </div>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

export default SendNewsletters;
