import React from 'react';
import 'bulma/css/bulma.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const GenericErrorPage = ({ message }) => {
    return (
        <section className="hero is-danger is-half">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="4x" />
                    <h1 className="title is-1">Oops!</h1>
                    <h2 className="subtitle is-3">{message}</h2>
                    <p>Please try again later or contact us if the issue persists.</p>
                </div>
            </div>
        </section>
    );
};

export default GenericErrorPage;
