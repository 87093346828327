import React, { useState } from "react";

const NewsletterForm = ({category, publication, frequency}) => {
  console.log("inside form", category, publication, frequency);
  if(!category) {
    category = "tinybyt";
  }
  const [formData, setFormData] = useState({
    category: category,
    email: "",
    frequency: frequency,
    following_publication: publication
  });

  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/newsletters/subscribe`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(formData),
        },
      );
      const data = await response.json();
      if (response.status === 400 && data.code === 11000) {
        setMessage(data.error);
      } else if (response.status !== 200) {
        setMessage(data.error);
      } else {
        setMessage(data.message);
      }
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className="section columns">
      <div className="container">
        {/*<h4 className="form-label mb-2">*/}
        {/*  <small>Subscribe to <b>{publication?publication:"TinyByt"}</b> and get each updated straight in your inbox.</small>*/}
        {/*</h4>*/}
        <form onSubmit={handleSubmit} className="field has-addons">
          <div className="control">
            <input
              className="input"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="control">
            <button className="button is-info">Subscribe</button>
          </div>
        </form>
        <div>
          <small style={{ color: "grey" }}>
            By signing up, you agree to our{" "}
            <a href="/user-agreement">User Agreement</a> and{" "}
            <a href="/privacy-policy">Privacy Policy.</a>
          </small>
        </div>
        {message.length > 0 && (
          <div className="message is-warning">
            <div className="message-body">{message}</div>
          </div>
        )}
      </div>
    </section>
  );
};

export default NewsletterForm;
