import userService from "../services/userService";
import { saveUserDataToLocalStorage } from "../../utils/localStorage"; // Import userService

// Action Types
export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";

// Action Creators
export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

// Action Types
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

// Action Creator for updating user profile
export const updateUserProfile = (userData) => ({
  type: UPDATE_USER_PROFILE,
  payload: userData,
});

export const loginWithGoogle = () => {
  return async (dispatch) => {
    try {
      const user = await userService.fetchUserData();
      console.log("login with google", user);
      dispatch(setUser(user)); // Dispatch setUser action with user data
      // Save user data to local storage
      saveUserDataToLocalStorage(user);
      // Go to home page
      window.location.replace("/");
    } catch (error) {
      // Handle login error
      console.error("Error logging in:", error);
    }
  };
};

// Async Action Creator for Login
export const loginUser = (email, password) => {
  return async (dispatch) => {
    try {
      const userData = await userService.login(email, password);
      localStorage.setItem("token", userData.token);
      const user = await userService.fetchUserData();
      console.log("user-data=", user);
      dispatch(setUser(user)); // Dispatch setUser action with user data
      // Save user data to local storage
      saveUserDataToLocalStorage(user);
      // Go to home page
      window.location.replace("/");
    } catch (error) {
      // Handle login error
      console.error("Error logging in:", error);
    }
  };
};

// Action Creator for logout
export const logout = () => {
  return async (dispatch) => {
    try {
      await userService.logout(); // Call userService logout method
      localStorage.removeItem("token"); // Remove token from localStorage
      localStorage.removeItem("userData");
      window.location.replace("/users/login");
      dispatch(logoutUser()); // Dispatch logout action
    } catch (error) {
      // Handle logout error
      console.error("Error logging out:", error);
    }
  };
};

// Async Action Creator for Update Profile
export const updateProfile = ({ name, email, profileImage }) => {
  return async (dispatch) => {
    try {
      const userData = await userService.updateProfile({
        name,
        email,
        profileImage,
      });
      dispatch(updateUserProfile(userData.user));
      saveUserDataToLocalStorage(userData.user);
    } catch (error) {
      // Handle login error
      console.error("Error logging in:", error);
    }
  };
};
