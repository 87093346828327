import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { loginUser } from "../redux/actions/userActions";
import { Link } from "react-router-dom";
import LoginWithGoogle from "./LoginWithGoogle";

const Login = ({ loginUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const handleSubmit = async (e) => {
    e.preventDefault();
    loginUser(email, password); // Dispatch loginUser action
  };

  useEffect(() => {
    if (isLoggedIn) {
      window.location.replace("/");
    }

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "40%" : "80%" }}
      >
        <h1 className="title">Login</h1>
        <LoginWithGoogle />
        <br />
        <hr />
        <p style={{ fontWeight: "bold" }}>
          Prefer to login with email and password?
        </p>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">Username</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button className="button is-primary is-fullwidth">Login</button>
            </div>
          </div>
        </form>
        <br />
        {/*<Link to='/forgot-password'>Forgot Password?</Link>*/}
        {/*<br />*/}
        Don't have an account? {"  "}
        <Link to="/users/signup">Sign Up?</Link>
        {message.length > 0 && (
          <div className="message is-danger">
            <div className="message-body">{message}</div>
          </div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, { loginUser })(Login);
