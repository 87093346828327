import React, { useState, useEffect } from "react";

function SubscribersCountConfirmed() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Make a GET request to the server to get the count
    fetch(`${process.env.REACT_APP_API_HOST}/newsletters/count/confirmed`)
      .then((res) => res.json())
      .then((data) => {
        setCount(data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <h4>Total Confirmed Subscribers: {count}</h4>
    </div>
  );
}
export default SubscribersCountConfirmed;
