import React, { useEffect, useState } from "react";
import SudokuGrid from "./SudokuGrid";

function generateSudokuGrid() {
  let grid = Array(9)
    .fill(null)
    .map(() => Array(9).fill(0));

  function canPlace(num, pos) {
    for (let i = 0; i < 9; i++) {
      const m = 3 * Math.floor(pos.row / 3) + Math.floor(i / 3);
      const n = 3 * Math.floor(pos.col / 3) + (i % 3);
      if (
        grid[pos.row][i] === num ||
        grid[i][pos.col] === num ||
        grid[m][n] === num
      ) {
        return false;
      }
    }
    return true;
  }

  function solve() {
    for (let i = 0; i < 81; i++) {
      const row = Math.floor(i / 9);
      const col = i % 9;

      if (grid[row][col] === 0) {
        for (let num = 1; num <= 9; num++) {
          if (canPlace(num, { row, col })) {
            grid[row][col] = num;
            if (solve()) {
              return true;
            } else {
              grid[row][col] = 0; // undo and try again
            }
          }
        }
        return false; // tried all numbers, none can be placed here
      }
    }
    return true; // all cells are filled
  }

  solve();
  return grid;
}

function generateSudokuPuzzle() {
  let grid = generateSudokuGrid();

  function solveSudoku(grid, row = 0, col = 0) {
    if (row === 9) {
      row = 0;
      if (++col === 9) return true;
    }
    if (grid[row][col] !== 0) return solveSudoku(grid, row + 1, col);

    for (let num = 1; num <= 9; num++) {
      if (isValid(grid, row, col, num)) {
        grid[row][col] = num;
        if (solveSudoku(grid, row + 1, col)) return true;
      }
    }

    grid[row][col] = 0; // undo assignment
    return false;
  }

  function isValid(grid, row, col, num) {
    for (let i = 0; i < 9; i++) {
      const m = 3 * Math.floor(row / 3) + Math.floor(i / 3);
      const n = 3 * Math.floor(col / 3) + (i % 3);
      if (grid[row][i] === num || grid[i][col] === num || grid[m][n] === num) {
        return false;
      }
    }
    return true;
  }

  function countSolutions(grid, row = 0, col = 0) {
    if (row === 9) {
      row = 0;
      if (++col === 9) return 1;
    }
    if (grid[row][col] !== 0) return countSolutions(grid, row + 1, col);

    let count = 0;
    for (let num = 1; num <= 9; num++) {
      if (isValid(grid, row, col, num)) {
        grid[row][col] = num;
        count += countSolutions(grid, row + 1, col);
      }
    }

    grid[row][col] = 0; // undo assignment
    return count;
  }

  // Convert filled grid to puzzle by removing some cells
  for (let i = 0; i < 81; i++) {
    // Decide whether to remove this cell
    if (Math.random() < 0.5) {
      const row = Math.floor(i / 9);
      const col = i % 9;

      const removed = grid[row][col];
      grid[row][col] = 0;

      const count = countSolutions(JSON.parse(JSON.stringify(grid)));
      if (count !== 1) {
        grid[row][col] = removed; // undo removal
      }
    }
  }

  // Convert to format with objects
  grid = grid.map((row) =>
    row.map((cell) => ({ value: cell, isInitial: cell !== 0 })),
  );

  return grid;
}

// Sudoku game component
function SudokuGame() {
  const [grid, setGrid] = useState(generateSudokuPuzzle());
  const [time, setTime] = useState(0);
  const [solved, setSolved] = useState(false);

  let timerId;
  useEffect(() => {
    timerId = setInterval(() => {
      setTime((time) => time + 1);
    }, 1000);

    if (isValidSudoku(grid)) {
      setSolved(true);
      clearInterval(timerId);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [grid]);

  function isValidSudoku(grid) {
    for (let i = 0; i < 9; i++) {
      let row = new Set(),
        col = new Set(),
        box = new Set();

      for (let j = 0; j < 9; j++) {
        let _row = grid[i][j].value;
        let _col = grid[j][i].value;
        let _box =
          grid[3 * Math.floor(i / 3) + Math.floor(j / 3)][3 * (i % 3) + (j % 3)]
            .value;

        if (_row > 0 && !row.has(_row)) row.add(_row);
        if (_col > 0 && !col.has(_col)) col.add(_col);
        if (_box > 0 && !box.has(_box)) box.add(_box);
      }

      if (row.size !== 9 || col.size !== 9 || box.size !== 9) return false;
    }
    return true;
  }

  const handleChange = (boxIndex, cellIndex, value) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    // If the value is empty string, set it to 0
    if (value === "") {
      newGrid[boxIndex][cellIndex].value = 0;
      newGrid[boxIndex][cellIndex].incorrect = false;
    }
    // Check if the value is a number between 1 and 9
    else if (!isNaN(value) && value >= 1 && value <= 9) {
      newGrid[boxIndex][cellIndex].value = parseInt(value);
    }
    setGrid(newGrid);
  };

  return (
    <section className="container">
      <div style={{ textAlign: "center" }}>
        <b>Time: {time}</b>
      </div>
      {solved && (
        <div
          className="notification is-success"
          style={{
            width: "fit-content",
            margin: "auto",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          Congratulations! You've solved the puzzle!
        </div>
      )}
      <SudokuGrid grid={grid} onChange={handleChange} />
    </section>
  );
}

export default SudokuGame;
