import React, { useState, useEffect } from "react";
import axios from "axios";
import {connect} from "react-redux";

const PodcastFeed = ({currentUser, isLoggedIn}) => {
    if(isLoggedIn && currentUser?.role === "admin") {
        // do nothing
    } else {
        localStorage.removeItem("token"); // Remove token from localStorage
        localStorage.removeItem("userData");
        window.location.replace("/users/login");
    }
    const [podcasts, setPodcasts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPodcasts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_HOST}/podcasts`);
                setPodcasts(response.data);
            } catch (error) {
                console.error("Error fetching podcasts:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPodcasts();
    }, []);

    const handleDelete = async (fileName) => {
        const confirmation = prompt("Type 'delete' to confirm the deletion of this podcast:");

        if (confirmation === "delete") {
            if (window.confirm("Are you sure you want to delete this podcast?")) {
                try {
                    await axios.delete(`${process.env.REACT_APP_API_HOST}/podcasts/${fileName}`);
                    setPodcasts(podcasts.filter(podcast => podcast.fileName !== fileName));
                } catch (error) {
                    console.error("Error deleting podcast:", error);
                }
            }
        } else {
            alert("Deletion cancelled. You must type 'delete' to confirm.");
        }
    };

    return (
        <div className="section">
            <div className="container">
                <h1 className="title">Podcast Feed</h1>
                {loading ? (
                    <p>Loading podcasts...</p>
                ) : (
                    <div className="columns is-multiline">
                        {podcasts.map((podcast, index) => (
                            <div key={index} className="column is-full">
                                <div className="card">
                                    <div className="card-content">
                                        <p className="title is-4">{podcast.fileName}</p>
                                        <p className="subtitle is-6">
                                            Last Modified: {new Date(podcast.lastModified).toLocaleDateString()}
                                        </p>
                                        <audio controls>
                                            <source src={podcast.url} type="audio/mpeg" />
                                        </audio>
                                        <button className="button is-danger" onClick={() => handleDelete(podcast.fileName)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
    isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(PodcastFeed);
