import React, { useEffect, useState } from "react";
import "bulma/css/bulma.css";
function UserAgreement() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className="section"
      style={{
        padding: "20px",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        <h1 className="title">User Agreement:</h1>
        <h4 className="label">1. Introduction:</h4>
        This User Agreement (“Agreement”) is a contract between you (“User” or
        “you”) and The TinyByt, the owner and operator of The TinyByt (“Website”
        or “Service”), and it governs your use of the Service.
        <br />
        <br />
        <h4 className="label">2. Description of Service:</h4>
        The Service provides users with access to a variety of resources,
        including but not limited to The TinyByt. The Service may be updated
        from time to time without notice to you.
        <br />
        <br />
        <h4 className="label">3. User Accounts:</h4>
        To use the Service, you must create a User account. You must provide
        accurate and complete information when creating your account, and you
        must keep this information up-to-date. You are solely responsible for
        maintaining the confidentiality of your account information, including
        your password. You are also responsible for all activities that occur
        under your account.
        <br />
        <br />
        <h4 className="label">4. User Content:</h4>
        The Service may allow you to submit, upload, publish or otherwise make
        available content, including but not limited to text, photographs,
        videos, and audio (“User Content”). You retain all rights in and are
        solely responsible for your User Content. By making any User Content
        available through the Service, you grant to The TinyByt a non-exclusive,
        transferable, sub-licensable, royalty-free, worldwide license to use,
        copy, modify, create derivative works based on, distribute, publicly
        display, publicly perform, and otherwise exploit such User Content in
        connection with the Service and The TinyByt’s (and its successors’ and
        affiliates’) business, including without limitation for promoting and
        redistributing part or all of the Service (and derivative works thereof)
        in any media formats and through any media channels.
        <br />
        <br />
        <h4 className="label">5. Proprietary Rights:</h4>
        The Service, including but not limited to The TinyByt’s trademarks,
        logos, and all other proprietary materials, are the property of The
        TinyByt or its licensors and are protected by India and international
        copyright and trademark laws. You may not use such proprietary materials
        in any way whatsoever except for using the Service in accordance with
        this Agreement.
        <br />
        <br />
        <h4 className="label">6. Disclaimer of Warranties:</h4>
        The Service is provided on an “as is” and “as available” basis. The
        TinyByt makes no representations or warranties of any kind, express or
        implied, as to the operation of the Service or the information, content,
        materials, or products included on the Service. You agree that your use
        of the Service is at your sole risk.
        <br />
        <br />
        <h4 className="label">7. Limitation of Liability:</h4>
        In no event will The TinyByt, its affiliates, licensors, employees,
        agents, or contractors be liable to you or any third party for any
        direct, indirect, incidental, special, punitive, or consequential
        damages arising out of your use of the Service or inability to use the
        Service, including without limitation any lost profits, loss of business
        or data, or damage to your computer or other device, even if The TinyByt
        has been advised of the possibility of such damages.
        <br />
        <br />
        <h4 className="label">8. Indemnification:</h4>
        The users agree to indemnify and hold us harmless from and against any
        and all claims, damages, costs, and expenses, including, but not limited
        to, reasonable attorneys’ fees, arising from or related to their use of
        our website or their violation of these terms.
        <br />
        <br />
        <h4 className="label">9. Termination:</h4>
        We may terminate or suspend the users’ access to our website at any
        time, with or without cause, with or without notice. Upon termination,
        the users’ license to use our website will automatically terminate.
        <br />
        <br />
        <h4 className="label">10. Governing Law:</h4>
        This agreement and any dispute arising from the use of the Website will
        be governed by the laws of the jurisdiction in which the Website is
        based. The parties agree to submit to the exclusive jurisdiction of the
        courts located within that jurisdiction.
      </div>
    </section>
  );
}
export default UserAgreement;
