import React, {useEffect, useState} from "react";
import axios from "axios";
import {connect} from "react-redux";

const GeneratePodcast = ({currentUser, isLoggedIn}) => {
    if(isLoggedIn && currentUser?.role === "admin") {
        // do nothing
    } else {
        localStorage.removeItem("token"); // Remove token from localStorage
        localStorage.removeItem("userData");
        window.location.replace("/users/login");
    }

    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [podcastUrl, setPodcastUrl] = useState("");

    const handleGeneratePodcast = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST}/podcasts/generate-podcast`, { text });
            setPodcastUrl(response.data.url);
        } catch (error) {
            console.error("Error generating podcast:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-6">
            <div className="box">
                <h1 className="title is-3 has-text-centered">Generate Podcast</h1>
                <form onSubmit={handleGeneratePodcast}>
                    <div className="field">
                        <label className="label">Podcast Text</label>
                        <div className="control">
              <textarea
                  className="textarea is-primary"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  rows="10"
                  placeholder="Enter text for podcast"
              ></textarea>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button className={`button is-primary is-fullwidth ${loading ? 'is-loading' : ''}`} type="submit" disabled={loading}>
                                {loading ? "Generating..." : "Generate Podcast"}
                            </button>
                        </div>
                    </div>
                </form>

                {podcastUrl && (
                    <div className="notification is-success mt-5">
                        <h2 className="title is-4">Podcast Generated</h2>
                        <div className="content">
                            <audio controls>
                                <source src={podcastUrl} type="audio/mpeg" />
                            </audio>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
    isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(GeneratePodcast);
