import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect, useSelector } from "react-redux";

const NewPublicationForm = () => {
  const [publicationName, setPublicationName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state?.user?.currentUser);

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.replace("/");
    }
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/publications`,
        { name: publicationName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setLoading(false);
      console.log("Publication created:", response.data);
      const landing_url = `/publication/${response.data.publication.name}`;
      window.location.replace(landing_url);
      // Optionally, you can redirect the user or show a success message here
    } catch (error) {
      setLoading(false);
      setError(error.message || "An error occurred");
      console.error("Publication creation error:", error);
    }
  };

  return (
    <div className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "90%" }}
      >
        <h1 className="title">New Publication</h1>
        {error && <div className="notification is-danger">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Publication Name"
                value={publicationName}
                onChange={(e) => setPublicationName(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                className={`button is-primary is-fullwidth ${
                  loading ? "is-loading" : ""
                }`}
                type="submit"
                disabled={loading}
              >
                Create Publication
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(NewPublicationForm);
