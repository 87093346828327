import BookSummaryInput from "./pages/BookSummaryInput";
import Navbar from "./pages/Navbar";
import Search from "./pages/Search";
import Login from "./pages/Login";
import { Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUp";
import NewsletterForm from "./pages/NewsletterForm";
import ConfirmSubscription from "./pages/NewsletterConfirmation";
import TopFeeds from "./pages/TopFeeds";
import LandingPage from "./pages/LandingPage";
import Footer from "./pages/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserAgreement from "./pages/UserAgreement";
import About from "./pages/About";
import ErrorPage from "./pages/ErrorPage";
import Contact from "./pages/Contact";
import SendNewsletters from "./pages/SendNewsletters";
import Unsubscribe from "./pages/Unsubscribe";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./pages/AdminDashboard";
import AIEditor from "./pages/AIEditor";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import AIEditorEdit from "./pages/AIEditorEdit";
import Bookmarks from "./pages/Bookmarks";
import Sudoku from "./pages/games/Sudoku";
import Podcasts from "./pages/Podcasts";
import DraftedArticles from "./pages/DraftedArticles";
import ProfilePage from "./pages/ProfilePage";
import NewPublicationForm from "./pages/NewPublicationForm";
import GeneratePodcast from "./pages/GeneratePodcast";
import PodcastFeed from "./pages/PodcastFeed";


function App() {
  return (
    <div className="container is-family-sans-serif">
      <Navbar />
      <br />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/books" element={<TopFeeds />} />
        <Route path="/podcasts" element={<PodcastFeed />} />
        {/*<Route path="/podcasts" element={<Podcasts />} />*/}
        <Route path="/articles/:category" element={<Articles />} />
        <Route path="/publication/:publication" element={<Articles />} />
        <Route path="/article/:slug" element={<Article />} />
        <Route path="/article/:slug/edit" element={<AIEditorEdit />} />
        <Route path="/add-book" element={<BookSummaryInput />} />
        <Route path="/users/login" element={<Login />} />
        <Route path="/users/signup" element={<SignUp />} />
        <Route path="/subscribe" element={<NewsletterForm />} />
        <Route path="/search" element={<Search />} />
        <Route path="/bookmarks" element={<Bookmarks />} />
        <Route path="/drafted-stories" element={<DraftedArticles />} />
        <Route path="/create-podcast" element={<GeneratePodcast />} />
        <Route
          path="/subscription/confirmation/:uuid"
          element={<ConfirmSubscription />}
        />
        <Route
          path="/subscription/unsubscribe/:uuid"
          element={<Unsubscribe />}
        />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/user-agreement" element={<UserAgreement />} />
        <Route path="/send-newsletters" element={<SendNewsletters />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/new-story" element={<AIEditor />} />
        <Route
          path="/publication/:publication/new-story"
          element={<AIEditor />}
        />
        <Route path="/publication/new" element={<NewPublicationForm />} />
        <Route path="/profile/:uuid" element={<ProfilePage />} />
        <Route path="/sudoku" element={<Sudoku />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
      <Footer />
      <br />
      <br />
    </div>
  );
}
export default App;
