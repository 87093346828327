import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

const BookSummaryInput = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state?.user?.currentUser);

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.replace("/");
    }
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/openai/text-processing`,
      {
        method: "POST",
        body: JSON.stringify({ input }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      },
    );
    const data = await response.text();
    const result = JSON.parse(data);
    setOutput(result.replace(/^"|"$/g, "").replace(/^<br><br>/, ""));
  };

  return user.role === "admin" ? (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "90%" }}
      >
        <h1 className="title">Add new book</h1>
        <form onSubmit={handleSubmit}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Book name|Book Author|Code"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-primary is-fullwidth">Submit</button>
            </div>
          </div>
        </form>
        <br />
        <div
          className="content is-size-6"
          dangerouslySetInnerHTML={{ __html: `${output}` }}
        />
      </div>
    </section>
  ) : (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        <h4>Preparing the page for you.</h4>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(BookSummaryInput);
