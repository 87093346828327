import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <>
      <h4>Error page</h4>
      Take me <Link to="/">Home</Link>
    </>
  );
};

export default ErrorPage;
