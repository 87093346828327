import React, { useEffect, useState } from "react";
import NewsletterForm from "./NewsletterForm";
import { useLocation, useParams } from "react-router-dom";

function Feed({ posts }) {
  const location = useLocation();
  const { publication } = useParams();
  let { category } = useParams();
  if (!category) {
    category = "tinybyt";
  }
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <section>
        <div
          className="container"
          style={{ maxWidth: width > 768 ? "50%" : "100%" }}
        >
          {posts.length === 0 && (
            <>
              <h1>It's empty here. Write new story!</h1>
            </>
          )}
          <>
            <div className="columns is-multiline">
              {posts.map((post, index) => (
                <div className="column is-full" key={index}>
                  <div
                    className="card"
                    onClick={() => {
                      window.location = `/article/${post["slug"]}`;
                    }}
                  >
                    <div className="card-content">
                      <p
                        style={{ fontFamily: "monospace" }}
                        className="title is-size-5"
                      >
                        {post.title}
                      </p>
                      <p
                        style={{ fontFamily: "monospace" }}
                        className="subtitle is-size-6"
                      >
                        {post.author_name && (
                          <small>By {post.author_name}</small>
                        )}
                      </p>
                      <div
                        className="content is-size-6"
                        style={{ fontFamily: "monospace", color: "grey" }}
                        dangerouslySetInnerHTML={{
                          __html: `${post.content
                            .replace(/^"|"$/g, "")
                            .replace(/^<br><br>/, "")
                            .replace(/\\/g, "")
                            .split(".")
                            .slice(0, 1)}`,
                        }}
                      />

                      {post.story_image && (
                        <div className="card-image">
                          <figure className="image is-4by3">
                            <img src={post.story_image} alt="story_image" />
                          </figure>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        </div>
        <br />
        <hr />
        <div className="is-flex is-justify-content-center">
          {location.pathname !== "/" && (
            <NewsletterForm
              category={category}
              publication={publication}
              frequency="weekly"
            />
          )}
        </div>
      </section>
    </>
  );
}

export default Feed;
