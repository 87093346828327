import React, { useEffect, useState } from "react";

function PodcastCard({ title, description, thumbnail, audioUrl }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <section className="section">
        <div
          className="container"
          style={{ maxWidth: width > 768 ? "50%" : "100%" }}
        >
          <>
            <div className="card">
              <div className="card-content">
                <span className="tags has-addons">
                  <small className="tag is-dark has-text-weight-bold is-size-7">
                    Podcast
                  </small>
                </span>
                <div className="columns is-vcentered">
                  <div className="column">
                    <p className="title is-4">{title}</p>
                    <p className="subtitle is-6">{description}</p>
                    <audio controls src={audioUrl}>
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </section>
    </>
  );
}

export default PodcastCard;
