import React, { useState, useEffect } from "react";

function RecordCount() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Make a GET request to the server to get the count
    fetch(`${process.env.REACT_APP_API_HOST}/contents/record/count`)
      .then((res) => res.json())
      .then((data) => {
        setCount(data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <h6>
        Search through a collection of {count} book summaries and counting.
      </h6>
    </div>
  );
}
export default RecordCount;
