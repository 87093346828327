// reducers/userReducer.js
const initialState = {
  currentUser: {},
  isLoggedIn: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      console.log("is it setting user?", action.payload);
      return {
        ...state,
        currentUser: action.payload,
        isLoggedIn: true,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        currentUser: null,
        isLoggedIn: false,
      };
    case "UPDATE_USER":
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    // Add more cases as needed
    default:
      return state;
  }
};

export default userReducer;
