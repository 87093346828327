import React, { useEffect, useState } from "react";
import SearchResults from "./SearchResults";
import { connect, useSelector } from "react-redux";
function Bookmarks() {
  const [bookMarks, setBookMarks] = useState([]);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state?.user?.currentUser);

  const getUser = async () => {
    return user;
  };

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.replace("/");
    }
    const fetchData = async () => {
      try {
        getUser()
          .then((data) => {
            return fetch(
              `${process.env.REACT_APP_API_HOST}/bookmarks/user/${data["_id"]}`,
            );
          })
          .then((response) => response.json())
          .then((data) => {
            const articleIds = data.map((obj) => obj.articleId).join(",");
            if (articleIds.length === 0) {
              setBookMarks(data);
              return;
            }
            return fetch(
              `${process.env.REACT_APP_API_HOST}/contents/many/${articleIds}`,
            );
          })
          .then((response) => response.json())
          .then((data) => {
            setBookMarks(data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="section columns">
      <div className="container column is-half">
        <br />
        <SearchResults contents={bookMarks} text={"Saved Articles"} />
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(Bookmarks);
