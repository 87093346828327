import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { loginWithGoogle } from "../redux/actions/userActions";
import { connect } from "react-redux";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const LoginWithGoogle = ({ loginWithGoogle }) => {
  const handleGoogleLoginSuccess = async (response) => {
    // get the token
    const token = response["credential"];

    // store the token in localstorage
    localStorage.setItem("token", token);

    // decode the token to get the user details
    const decoded = jwt_decode(token);

    // send the details to your server to save the user data
    try {
      const email = decoded["email"];
      const name = decoded["name"];
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/users/login/oauth2`,
        { name, email },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      );
      if (response.status === 200) {
        loginWithGoogle();
      } else {
        console.log("Login failed");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google Login Failed", error);
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        buttonText="Login with Google"
        theme="outline"
        onSuccess={handleGoogleLoginSuccess}
        onFailure={handleGoogleLoginFailure}
        cookiePolicy={"single_host_origin"}
        responseType="code,token"
        scope={["profile", "email"]}
      />
    </GoogleOAuthProvider>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, { loginWithGoogle })(LoginWithGoogle);
