import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { logout, logoutUser } from "../redux/actions/userActions";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";

function Navbar() {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const currentAuthStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/users/current_user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.data === null) {
          localStorage.removeItem("token"); // Remove token from localStorage
          localStorage.removeItem("userData");
          // window.location.replace("/users/login");
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    currentAuthStatus();

    const fetchPublications = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/publications/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setPublications(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchPublications();
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const pattern = /^\/publication\/[a-zA-Z0-9_-]+$/;
  const isPublicationURL = pattern.test(pathname);
  let pub_name = "";
  if (isPublicationURL) {
    pub_name = pathname.split("/")[2];
  }

  const [isActive, setIsActive] = useState(false);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state?.user?.currentUser);
  const isAdmin = useSelector(
    (state) => state?.user?.currentUser?.role === "admin",
  );

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src="/logo.png" width="112" height="28" alt="logo" />
        </a>
        <a
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          onClick={() => setIsActive(!isActive)}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          <a className="navbar-item" href="/sudoku">
            Sudoku
          </a>
        </div>
        {!isLoggedIn && (
          <div className="navbar-end">
            {/*<div className="navbar-item">*/}
            {/*  <div className="buttons">*/}
            {/*    <a className="button is-primary" href="/users/signup">*/}
            {/*      <strong>Sign up</strong>*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="navbar-item">
              <div>
                <a href="/users/login">
                  <strong>Log in</strong>
                </a>
              </div>
            </div>
          </div>
        )}
        {isLoggedIn && (
          <>
            <div className="navbar-end">
              <div className="navbar-item">
                {!location.pathname.includes("new-story") && (
                  <div className="buttons">
                    {isPublicationURL ? (
                      <a
                        className="button is-success is-small is-rounded"
                        href={`/publication/${pub_name}/new-story`}
                      >
                        <strong>New Story</strong>
                      </a>
                    ) : (
                      <a
                        className="button is-success is-small is-rounded"
                        href="/new-story"
                      >
                        <strong>New Story</strong>
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                {user ? (
                  <img
                    src={user.profile_image}
                    alt="User Thumbnail"
                    className="user-thumbnail"
                    style={{
                      height: "40px",
                      width: "30px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <FaUserCircle size={28} />
                )}
              </a>

              <div className="navbar-dropdown is-right">
                <Link to={`/profile/${user.uuid}`} className="navbar-item">
                  Profile Details
                </Link>
                <hr className="navbar-divider" />
                <Link to="/drafted-stories" className="navbar-item">
                  Drafted Stories
                </Link>
                <Link to="/bookmarks" className="navbar-item">
                  Bookmarked Stories
                </Link>
                <hr className="navbar-divider" />
                <p className="ml-3 has-text-weight-bold">Publications</p>
                {publications.map((publication) => (
                  // <li key={publication._id}>{publication.name}</li>
                  <a
                    key={publication.name}
                    href={`/publication/${publication.name}`}
                    className="navbar-item"
                  >
                    {publication.name}
                  </a>
                ))}

                <a
                  className="button is-small is-rounded is-success is-primary ml-3"
                  href="/publication/new"
                >
                  <strong>New Publication</strong>
                </a>

                {isAdmin && (
                  <>
                    <hr className="navbar-divider" />
                    <Link to="/admin-dashboard" className="navbar-item">
                      Admin Dashboard
                    </Link>
                    <Link to="/create-podcast" className="navbar-item">
                      Create Podcasts
                    </Link>
                    <Link to="/podcasts" className="navbar-item">
                      List Podcasts
                    </Link>
                  </>
                )}
                <hr className="navbar-divider" />
                <a
                  className="ml-3"
                  href="#"
                  onClick={logout()}
                >
                  <strong>Log out</strong>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, { logout })(Navbar);
