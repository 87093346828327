import React, { useEffect, useState } from "react";
import TopFeeds from "./TopFeeds";
import PodcastCard from "../components/PodcastCard"; // Adjust the path as necessary

const LandingPage = () => {
  return (
    <div className="section">
      <section className="hero">
        <div>
          <div className="container has-text-centered">
            <p
              className="is-size-6 is-capitalized"
              style={{
                fontFamily: "monospace",
              }}
            >
              Byte size knowledge that makes you smarter everyday. <br />
              <a
                href="/subscribe"
                style={{
                  fontStyle: "italic",
                  fontWeight: "normal",
                }}
              >
                &nbsp; <u>Subscribe Today</u>
              </a>
            </p>
            <br />
          </div>
        </div>
      </section>
      <>
        {/*<PodcastCard*/}
        {/*    title="Benefits of running"*/}
        {/*    description="This episode starts with talking about benefits of running."*/}
        {/*    thumbnail="https://images.unsplash.com/32/Mc8kW4x9Q3aRR3RkP5Im_IMG_4417.jpg?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"*/}
        {/*    audioUrl="https://mcdn.podbean.com/mf/web/hvpjjp/Ep1.mp3"*/}
        {/*/>*/}
        <TopFeeds />
      </>
    </div>
  );
};

export default LandingPage;
