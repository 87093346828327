import React, { useEffect, useState } from "react";
import Feed from "./Feed";
import { useParams } from "react-router-dom";

function Articles() {
  const [articles, setArticles] = useState([]);
  let { category } = useParams();
  let { publication } = useParams();
  useEffect(() => {
    if (category) {
      fetch(`${process.env.REACT_APP_API_HOST}/contents/category/${category}`)
        .then((response) => response.json())
        .then((results) => setArticles(results));
    }

    if (publication) {
      fetch(
        `${process.env.REACT_APP_API_HOST}/contents/publication/${publication}`,
      )
        .then((response) => response.json())
        .then((results) => setArticles(results));
    }
  }, []);
  return (
    <>
      <Feed posts={articles} />
    </>
  );
}
export default Articles;
