import React, { useEffect, useState } from "react";
import UsersCount from "./UsersCount";
import SubscribersCount from "./SubscribersCount";
import { connect } from "react-redux";
import SubscribersCountConfirmed from "./SubscriptionCountConfirmed";

function AdminDashboard({ currentUser }) {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return currentUser?.role === "admin" ? (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        <h1 className="title">Admin Dashboard</h1>
        <UsersCount />
        <hr />
        <SubscribersCount />
        <hr />
        <SubscribersCountConfirmed />
        <hr />
        <h1 className="title">Admin Actions</h1>
        <br />
        <p>Add Book works only on localhost</p>
        <a href="/add-book" className="button is-primary is-fullwidth">
          Add Book
        </a>
      </div>
    </section>
  ) : (
    <section className="section">
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        <h4>Preparing the page for you.</h4>
      </div>
    </section>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(AdminDashboard);
