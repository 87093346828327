import { useEffect, useState } from 'react';
import Feed from './Feed';
import GenericErrorPage from "../components/GenericErrorPage"; // Make sure to import your Feed component


function TopFeeds() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const category = "all";

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/contents/category/${category}`)
        .then((response) => {
          if (response.status === 429) {
            throw new Error('Too many requests.');
          }
          if (!response.ok) {
            throw new Error('Something went wrong while processing');
          }
          return response.json();
        })
        .then((results) => {
          setArticles(results);
        })
        .catch((error) => {
          setError(error.message);
        });
  }, [category]);

  return (
      <>
        {error ? (
            <GenericErrorPage message={error} />
        ) : (
            <Feed posts={articles} />
        )}
      </>
  );
}

export default TopFeeds;
