import React, { useState } from "react";

function SearchResults(props) {
  console.log("content", props.contents);

  return (
    <div>
      {props.contents.length > 0 && (
        <>
          <hr style={{ color: "lightgrey" }} />
          <h5>{props.text}</h5>
        </>
      )}

      <div className="columns is-multiline">
        {props.contents.map((content, index) => (
          <div className="column is-half" key={index}>
            <div
              className="card"
              onClick={() => {
                window.location = `/article/${content.slug}`;
              }}
            >
              <div className="card-content">
                <span className="tags has-addons">
                  {/*<span className="tag is-dark">category</span>*/}
                  {content.category === "book_summary" && (
                    <small className="tag is-success has-text-weight-bold is-size-7">
                      Book Summary
                    </small>
                  )}

                  {content.category === "technology" && (
                    <span className="tag is-warning has-text-weight-bold is-size-7">
                      Technology
                    </span>
                  )}
                </span>
                <p className="title is-4 is-size-6">
                  {content.title.slice(0, 25)}
                </p>
                <p className="subtitle is-6 is-size-6">
                  By{" "}
                  {content.author_name ? content.author_name : content.author}
                </p>
                <div
                  className="content is-size-6"
                  dangerouslySetInnerHTML={{
                    __html: `${content.content
                      .replace(/^"|"$/g, "")
                      .replace(/^<br><br>/, "")
                      .replace(/\\/g, "")
                      .slice(0, 50)}...`,
                  }}
                />
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={content.story_image} alt="story_image" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {props.contents.length === 0 && (
        <>
          <hr style={{ color: "lightgrey" }} />
          <h5>It's empty here.</h5>
        </>
      )}
    </div>
  );
}

export default SearchResults;
