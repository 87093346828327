import "bulma/css/bulma.css";
import "../../App.css";
import { useEffect, useState } from "react";

/// SudokuCell component
function SudokuCell({ value, onChange, disabled, incorrect }) {
  return (
    <input
      className={`input ${incorrect ? "is-danger" : ""}`}
      type="text"
      value={value ? value : ""}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

// SudokuBox component
function SudokuBox({ box, boxIndex, onChange }) {
  return (
    <div className="box-style">
      {box.map((cell, cellIndex) => (
        <SudokuCell
          key={`${boxIndex}-${cellIndex}`}
          value={cell.value}
          onChange={(e) => onChange(boxIndex, cellIndex, e.target.value)}
          disabled={cell.isInitial}
          incorrect={cell.incorrect}
        />
      ))}
    </div>
  );
}

// SudokuGrid component
function SudokuGrid({ grid, onChange }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="container"
      style={{ maxWidth: width > 768 ? "100%" : "90%" }}
    >
      <div className="grid-style">
        {grid.map((box, boxIndex) => (
          <SudokuBox
            key={boxIndex}
            box={box}
            boxIndex={boxIndex}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
}

export default SudokuGrid;
