import React, { useEffect, useState } from "react";
import "bulma/css/bulma.css";
function PrivacyPolicy() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className="section"
      style={{
        padding: "20px",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: width > 768 ? "50%" : "100%" }}
      >
        <h1 className="title">Privacy Statement</h1>
        We take the privacy of our users very seriously. This Privacy Statement
        sets out our policies and procedures on the collection, use and
        disclosure of personal information that we collect from our users
        through our website and any related services.
        <br />
        <br />
        <h4 className="label">Collection of Information</h4>
        When you sign up for our website, we collect your name and email
        address. When you subscribe to our newsletter, we collect your email
        address.
        <br />
        <br />
        <h4 className="label">Use of Information</h4>
        We use the information collected from our users to provide better
        services and to communicate with them, such as sending newsletters,
        promotional offers and updates.
        <br />
        <br />
        <h4 className="label">Disclosure of Information</h4>
        We do not sell, trade, or otherwise transfer to outside parties your
        personally identifiable information unless we provide users with advance
        notice. This does not include website hosting partners and other parties
        who assist us in operating our website, conducting our business, or
        servicing you, so long as those parties agree to keep this information
        confidential.
        <br />
        <br />
        <h4 className="label">Security of Information</h4>
        We take appropriate measures to ensure the security of our users'
        personal information. However, no method of transmission over the
        Internet, or method of electronic storage, is 100% secure. Therefore,
        while we strive to use commercially acceptable means to protect your
        personal information, we cannot guarantee its absolute security.
        <br />
        <br />
        <h4 className="label">Changes to this Privacy Statement</h4>
        We may update this Privacy Statement from time to time. We encourage you
        to periodically review this Privacy Statement to stay informed about how
        we are protecting the personal information we collect. Your continued
        use of the services constitutes your agreement to this Privacy Statement
        and any updates.
        <br />
        <br />
        <h4 className="label">Contact Information</h4>
        If you have any questions about this Privacy Statement, the practices of
        this website, or your dealings with us, please contact us at
        hello@tinybyt.com
      </div>
    </section>
  );
}
export default PrivacyPolicy;
